import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/pt_PT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      
                      <li>
                        <a href="/es/nuestros-productos/active-protect/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/active-protect/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/active-protect/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            {/* <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2019-slide-active.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2019-slide-active-mobile.jpg"
                  />
                </div>
              </div>
            </section> */}
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      Piz Buin Active &amp; Protect<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Extra resistente à água ON THE GO Formato viagem.</h3>
                      <p>
                        Proteção contra cloro, suor e água para uma hidratação
                        duradoura e intensa. Absorção Rápida agora em formato de
                        tamanho de viagem.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="actect" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-active-protect-sun-lotion-100ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        PIZ BUIN ACTIVE &amp; PROTECT LOÇÃO ON THE GO FPS30
                        100ml
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          - Contém antioxidante FeverfewPFE™ para ajudar a
                          aumentar a tolerância da pele* ao sol.
                        </p>
                        <p>
                          - Contém filtros solares UVA/UVB avançados para uma
                          proteção imediata e eficaz.
                        </p>
                        <p>
                          - Ajuda a proteger a pele contra os agentes
                          agressores, como o cloro e a água do mar.
                        </p>
                        <p>- Proteção contra os raios UVA longos.</p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponível nos fatores de Proteção Solar</h4>
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Recomendação de Uso</h4>
                        <p>
                          Aplique em doses generosas e uniformemente antes da
                          exposição ao sol. A aplicação de uma dose reduzida
                          diminui significativamente o nível de proteção.
                          Reaplique frequentemente, especialmente depois de
                          transpirar, nadar ou após secar-se com uma toalha.
                          Evite a exposição solar ao meio-dia e ao longo de
                          muitas horas, mesmo quando utiliza proteção solar.
                        </p>
                        <p>* Teste in vitro</p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              Ingredientes e Tecnologias
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>
                                    Aqua, C12-15 Alkyl Benzoate, Ethylhexyl
                                    Salicylate, Octocrylene, Glycerin, Butylene
                                    Glycol, Silica, Dicaprylyl Carbonate, Butyl
                                    Methoxydibenzoylmethane, Butyrospermum
                                    Parkii Butter, VP/Eicosene Copolymer,
                                    Methylene bisbenzotriazolyl
                                    tetramethylbutylphenol (nano),
                                    Styrene/Acrylates Copolymer, Cetyl Alcohol,
                                    Glyceryl Stearate, Bis-Ethylhexyloxyphenol
                                    Methoxyphenyl Triazine, Diethylamino
                                    Hydroxybenzoyl Hexyl Benzoate, Chrysanthemum
                                    Parthenium Flower Extract, Glycine Soja Oil,
                                    Acrylates Copolymer, Ceteth-20, Linseed
                                    Acid, Decyl Glucoside, Caprylyl Glycol,
                                    Propylene Glycol, PEG-75 Stearate, PEG-8
                                    Laurate, Sodium Dodecylbenzenesulfonate,
                                    Potassium Cetyl Phosphate, Steareth-20,
                                    Cetearyl Alcohol, Sodium Polyacrylate,
                                    Xanthan Gum, Disodium EDTA, Tocopherol,
                                    Phenoxyethanol, Potassium Sorbate, Parfum.
                                    [PR-013862]
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/pt/nossos-produtos/active-protect/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/pt/nossos-produtos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/pt/nossos-produtos/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/pt/nossos-produtos/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/pt/nossos-produtos/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/pt/nossos-produtos/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/pt/nossos-produtos/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a
                      href="/pt/nossos-produtos/todos_os_Produtos/"
                      target="_self"
                    >
                      Todos os Produtos
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Saiba Mais');\n} else {\nbox.slideDown();\n$(this).text('Fechar');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
