import React from "react";

class Footer extends React.Component {
  render() {
    return (
<footer id="footer">
              <div className="insideFooter">
                <ul className="nav">
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">Cookie Policy</a>
                </li>
                  <li>
                    <a href="/pt/legal/contactos/" target="_self">
                      Contactos
                    </a>
                  </li>
                  <li>
                    <a href="/pt/legal/aviso_legal/" target="_self">
                      Aviso Legal
                    </a>
                  </li>
                  <li>
                    <a href="/pt/legal/politica_de_privacidade/" target="_self">
                      Política de Privacidade
                    </a>
                  </li>
                  <li>
                    <a href="/pt/legal/politicia-de-cookies/" target="_self">
                      Política de Cookies
                    </a>
                  </li>
                </ul>
                <div className="clear" />
                <p>
                  ©  JNTL Consumer Health (Portugal) Limitada 2023
                  <br /> Este site é publicado pela JNTL Consumer Health (Portugal) Limitada,
                  <br /> única responsável pelo seu conteúdo.
                  <br /> Esta página é dirigida a residentes de Portugal.
                  PT/PZB/15-0624
                  <br /> Data da última atualização: 01/02/2023.
                </p>
                <p> </p>
              </div>
            </footer>
    );
  }
}

export default Footer;
