/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 import React from "react";
 import { Helmet } from "react-helmet";
 import Page from "./Page";
 import Gtm from "src/components/Gtm";
 
 export default class IndexPage extends React.Component {
   render() {
     if (typeof window !== "undefined") {
       return (
         <div
           dangerouslySetInnerHTML={{
             __html: "https://github.com/facebook/react/issues/10923"
           }}
         />
       );
     }
     return (
       <React.Fragment>
         <Page />
         <Helmet>
           <html lang="pt" />
           <body className="our-products desktop" id />
           <meta charSet="UTF-8" />
           <meta name="description" content />
           <meta name="Keywords" content />
           <meta
             content="width=device-width,initial-scale=1, minimum-scale=0.5, maximum-scale=1.0"
             name="viewport"
           />
           <title>Piz Buin</title>
           <link
             href="//fonts.googleapis.com/css?family=Open+Sans:400,800,700,600,300&subset=latin"
             rel="stylesheet"
             type="text/css"
           />
           <link
             href="//fast.fonts.net/cssapi/a23edeb8-5888-4291-b2f7-2b67a0b2708d.css"
             type="text/css"
             rel="stylesheet"
           />
           <link
             type="text/css"
             href="/assets/css/styles.css"
             rel="stylesheet"
           />
           <link
             type="text/css"
             href="/assets/css/vendor/iealert.css"
             rel="stylesheet"
           />
           <script
             type="text/javascript"
             innerHTML="
 var lang = 'pt';
 "
           />
           <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
           <title>PIZ BUIN® - INSTANT GLOW</title>
           <meta name="description" content />
           <meta
             name="keywords"
             content="PIZ BUIN,
    pizbuin,
    piz-buin,
    sun,
    glow,
    instant-glow
    sun_protection,
    sunprotection,
    protection,
    sun_screen,
    sunscreen,
    tan_tanning, 
    sun_lotion, 
    sun_cream,  
    sunlotion, 
    suncream, "
           />
           
           <link
             rel="shortcut icon"
             href="/assetsassets/icons/favicon.ico"
             type="image/x-icon"
           />
           <link
             rel="icon"
             href="/assetsassets/icons/favicon.ico"
             type="image/x-icon"
           />
           <link
             rel="stylesheet"
             type="text/css"
             href="/assets/css/vendor/ccm.base.css"
           />
           <script type="text/javascript" src="/assets/js/vendor/jquery.js" />  
 
 <script type="text/javascript" src="/assets/js/vendor/jquery-migrate-3.0.0.min.js" />
           <script type="text/javascript" src="/assets/js/vendor/ccm.base.js" />
           <script type="text/javascript">
 function OptanonWrapper() { }
 </script>
           
         </Helmet>
         <Gtm />
         ;
       </React.Fragment>
     );
   }
 }
 